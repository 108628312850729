<template>
  <div
    class="hero-image-container"
    :style="`height: ${setContainerHeight}`"
    :id="content.id || ''"
  >
    <div class="hero-image-sidebar">
      <div class="hero-image-sidebar-content">
        <div
          :class="[
            'hero-image-sidebar-headline',
            {
              'hero-image-sidebar-headline--summer':
                content.headlineStyle == 'Summer',
              'hero-image-sidebar-headline--winter':
                content.headlineStyle == 'Winter',
              'hero-image-sidebar-headline--neubrutalism':
                content.headlineStyle == 'Neubrutalism',
            },
          ]"
        >
          {{ content.headline }}
        </div>
        <div
          :class="[
            'hero-image-sidebar-sub-headline',
            {
              'hero-image-sidebar-sub-headline--summer':
                content.headlineStyle == 'Summer',
              'hero-image-sidebar-sub-headline--winter':
                content.headlineStyle == 'Winter',
              'hero-image-sidebar-sub-headline--neubrutalism':
                content.headlineStyle == 'Neubrutalism',
            },
          ]"
        >
          {{ content.subHeadline }}
        </div>
      </div>
    </div>
    <div class="hero-image" v-if="!isVideo">
      <img
        v-if="isMobile && !!content.mediaMobile"
        :src="content.mediaMobile.fields?.file?.url"
        @load="mediaIsLoaded"
      />
      <img
        v-else
        :src="content.media.fields?.file?.url"
        @load="mediaIsLoaded"
      />
    </div>
    <div class="hero-image" v-else-if="isVideo">
      <video
        autoplay
        muted
        playsinline
        loop
        preload="metadata"
        style="pointer-events: none"
      >
        <source
          v-if="isMobile && !!content.mediaMobile"
          :src="`${content.mediaMobile?.fields?.file?.url}#t=0.1`"
          type="video/mp4"
        />
        <source
          v-else
          :src="`${content.media?.fields?.file?.url}#t=0.1`"
          type="video/mp4"
        />
      </video>
    </div>
    <div v-if="creatorList.length > 1 && !isVideo" class="hero-creator">
      <a
        v-for="(creator, index) in creatorList"
        :href="creator.url"
        :key="index"
      >
        {{ creator.name }}
      </a>
    </div>
    <div
      v-else-if="content.creatorName && content.creatorUrl && !isVideo"
      class="hero-creator"
    >
      <a :href="content.creatorUrl">{{ content.creatorName }}</a>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "HeroImage",
  props: {
    content: [Array, Object],
  },
  data() {
    return {
      creatorList: [],
      isLoaded: false,
    };
  },
  watch: {
    content() {
      this.creatorList = this.splitCreators();
    },
  },
  mounted() {
    this.creatorList = this.splitCreators();
  },
  computed: {
    isVideo() {
      const url = this.content.media.fields?.file?.url;
      return (
        url.endsWith(".webm") || url.endsWith(".mp4") || url.endsWith(".mov")
      );
    },
    isMultipleCreatorName() {
      return this.content.creatorName?.includes(";");
    },
    isMultipleCreatorUrl() {
      return this.content.creatorName?.includes(";");
    },
    isMobile() {
      const windowWidth = ref(window.innerWidth);

      if (windowWidth.value < 576) return true;
      return false;
    },
    setContainerHeight() {
      const body = document.body;
      const height = body.offsetHeight;
      const heightWithoutHeader = height - 96 + "px";

      return heightWithoutHeader;
    },
  },
  methods: {
    mediaIsLoaded() {
      this.isLoaded = true;
    },
    splitCreators() {
      var newList = [];

      if (this.isMultipleCreatorName && this.isMultipleCreatorUrl) {
        var names = [];
        var urls = [];

        if (this.isMobile && !!this.content.creatorNameMobile) {
          names = this.content.creatorNameMobile.split(";");
          urls = this.content.creatorUrlMobile.split(";");
        } else {
          names = this.content.creatorName.split(";");
          urls = this.content.creatorUrl.split(";");
        }

        for (var i = 0; i < names.length; i++) {
          newList.push({
            name: names[i] ?? "",
            url: urls[i] ?? "",
          });
        }
      } else {
        newList = {
          name: this.content.creatorName,
          url: this.content.creatorUrl,
        };
      }

      return newList;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_animation.scss";
@import "../assets/scss/_variables.scss";

.hero-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center 0;
    z-index: 0;
    animation: appear 1s ease;
  }

  &-container {
    position: relative;
    height: calc(100vh - 96px);
    margin-bottom: $spacing-xl;
    width: 100%;
  }

  &-sidebar {
    position: relative;
    height: 100%;
    z-index: 1;

    @media (min-width: $md) {
      background: $sewcase-black;
      width: 250px;
    }

    &-content {
      animation: title-slide-mobile 1s ease, appear 1.4s ease;
      position: absolute;
      bottom: 32px;
      left: 16px;
      overflow-wrap: anywhere;

      @media (min-width: $md) {
        animation: title-slide-desktop-md 1s ease, appear 1.4s ease;
        bottom: 48px;
        left: 48px;
        white-space: nowrap;
      }

      @media (min-width: $lg) {
        animation: title-slide-desktop-lg 1s ease, appear 1.4s ease;
        bottom: 64px;
        left: 64px;
      }
    }

    &-headline,
    &-sub-headline {
      color: #e3e1de;
      left: 16px;
      text-transform: uppercase;
      text-shadow: -2px 0 $sewcase-black, 0 2px $sewcase-black,
        2px 0 $sewcase-black, 0 -2px $sewcase-black;
    }

    &-headline {
      bottom: 96px;
      font-size: calc($font-hero-sm - 8px);

      @media (min-width: $sm) {
        font-size: calc($font-hero-sm - 4px);
      }

      @media (min-width: $md) {
        font-size: calc($font-hero-md - 4px);
      }

      @media (min-width: $lg) {
        font-size: calc($font-hero-lg - 4px);
      }

      &--summer,
      &--winter {
        @media (min-width: $md) {
          bottom: 108px;
        }
      }
    }

    &-sub-headline {
      bottom: 48px;
      font-size: $font-hero-xl;

      @media (min-width: $md) {
        font-size: calc($font-hero-lg + 4px);
      }

      @media (min-width: $lg) {
        font-size: calc($font-hero-xl + 4px);
      }

      &--summer {
        text-shadow: 6px 6px #fb0;
        -webkit-text-stroke-width: 1px;
        -moz-text-stroke-width: 1px;
        -webkit-text-stroke-color: #d55;
        -moz-text-stroke-color: #d55;
        color: transparent;
      }

      &--winter {
        text-shadow: 6px 6px #6f42c1;
        -webkit-text-stroke-width: 3px;
        -moz-text-stroke-width: 3px;
        -webkit-text-stroke-color: #00b9ff;
        -moz-text-stroke-color: #00b9ff;
        color: $sewcase-white;
      }

      &--neubrutalism {
        text-shadow: 6px 5px #ff54ec;
        color: #fff;
        -webkit-text-stroke-width: 3px;
      }
    }
  }
}

.hero-creator {
  display: flex;
  flex-direction: column;
  bottom: -32px;
  position: absolute;
  z-index: 1;
  right: 0;

  @media (min-width: $sm) {
    flex-direction: row;
    bottom: 0;
  }

  > a {
    background: rgb(33, 33, 33);
    border: 2px solid #fafafa;
    border-radius: 5px;
    color: white;
    font-size: calc($spacing-md - 2px);
    margin-right: 16px;
    text-decoration: none;
    padding: 5px 8px;
    transition: all 0.3s ease;

    &:hover {
      color: $sewcase-secondary-light;
    }
  }
}
</style>
