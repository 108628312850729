<template>
  <div class="component-section component-section--content">
    <template v-if="this.getLanguage == 'de-DE'">
      <h2>Vielen Dank für deine Zeit und Geduld.</h2>
      <p v-if="!files.length">
        Dein Shooting wird bald hochgeladen. Falls es bis zum 8. Juli noch nicht
        verfügbar ist, kontaktiere uns bitte per E-Mail an info@sewcase.de.
      </p>
      <p v-if="files.length">
        Wir hoffen dich bald wieder auf einer Convention anzutreffen. Es hat
        unglaublich viel spaß gemacht und mit einer Verzögerung sind die Bilder
        nun auch Verfügbar.
      </p>
      <p v-if="files.length">
        Klicke auf die jeweiligen Bilder um die Vollansicht und in bester
        Qualität zu bekommen.
      </p>
      <p v-if="files.length">
        <strong>
          Solltest du Mobiledaten verwenden, empfehlen wir das nächste W-Lan zu
          nutzen, da die Bilder in voller größe recht groß sind.
        </strong>
      </p>
      <div v-if="copyright" class="gallery-copyright">
        Bilder wurden aufgenommen von:
        <span v-html="copyright" />
      </div>
    </template>
    <template v-else>
      <h2>Thank you for your time and patience.</h2>
      <p v-if="!files.length">
        Your photoshoot will be uploaded soon. If it is not available by July
        8th available, please contact us by e-mail at info@sewcase.de.
      </p>
      <p v-if="files.length">
        We hope to see you again soon at a convention. It has been fun and with
        a delay the pictures are now available.
      </p>
      <p v-if="files.length">
        Click on the respective images to get the full view and in best quality.
      </p>
      <p v-if="files.length">
        <strong>
          If you use mobile data, we recommend to use the nearest wifi, because
          the full size images are quite large. in full size.
        </strong>
      </p>
      <div v-if="copyright" class="gallery-copyright">
        Images were taken by:
        <span v-html="copyright" />
      </div>
    </template>
    <div class="gallery">
      <template v-for="file in filesList" :key="file">
        <div class="gallery-image">
          <a
            :href="`https://sewcase.org/root/gallery/${this.pathnames[2]}_large/${file}.jpg`"
            target="_blank"
          >
            <img
              :src="`https://sewcase.org/root/gallery/${this.pathnames[2]}_small/${file}_small.jpg`"
            />
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  name: "image-page",
  data() {
    return {
      files: 0,
      filesList: [],
      pathnames: "",
      info: [],
      copyright: null,
    };
  },
  async created() {
    this.pathnames = window.location.pathname;
    this.pathnames = this.pathnames.split("/");
    const fetchUrl = `https://sewcase.org/root/gallery/${this.pathnames[2]}_small/files.txt`;

    await fetch(fetchUrl)
      .then((response) => response.text())
      .then((data) => {
        const splittedData = data.split("\n");
        for (var i = 1; i <= parseInt(splittedData[0]); i++) {
          this.filesList.push(i);
        }
        if (splittedData?.[1]?.length) {
          this.copyright = splittedData[1];
        }
      })
      .catch(() => {
        return "couldn't find any images... please contact us.";
      });
  },
  computed: {
    ...mapGetters(["getLanguage"]),
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px 0;
  padding: 16px 0;

  .gallery-image {
    border: 2px solid transparent;
    flex: 0 0 calc(100%);
    max-height: 200px;
    overflow: hidden;
    transition: all 0.3s ease;
    transition-delay: 0.1s;

    @media (min-width: $sm) {
      flex: 0 0 calc(33% - 16px);
    }

    &:hover {
      border: 2px solid $sewcase-secondary;

      img {
        transform: scale(1.2);
      }
    }

    img {
      cursor: pointer;
      clip-path: polygon(
        calc(100% - 16px) 0,
        100% calc(16px),
        100% 100%,
        0 100%,
        0 0
      );
      transition: all 0.3s ease;
      width: 100%;
      max-height: 156px;
      object-fit: cover;
      object-position: top;
    }
  }
}

.gallery-copyright {
  background: rgba($sewcase-secondary, 0.5);
  border-radius: 2px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 24px;
  position: relative;
  width: calc(100% - 28px);

  a {
    color: $sewcase-secondary-dark;

    &:hover {
      color: $sewcase-secondary;
    }
  }
}
</style>
