<template>
  <div class="component-section component-section--content error-page">
    <img src="../assets/images/poro_sewcase.png" @click="redirectToHome" />
    <template v-if="this.getLanguage == 'de-DE'">
      <div class="error-content">
        <h2>404 Seite nicht gefunden.</h2>
        <h3>
          Entweder existiert die gesuchte Seite nicht oder unsere Poro's haben
          die gesuchte Seite gegessen. Seid den Poro's bitte nicht böse.
        </h3>
        <h3>Klicke auf den Poro um auf die Startseite zu gelangen.</h3>
      </div>
    </template>
    <template v-else>
      <div class="error-content">
        <h2>404 Page not found.</h2>
        <h3>
          Either the page you are looking for does not exist or our Poros have
          eaten the page you are looking for. Please don't be angry with the
          Poros.
        </h3>
        <h3>Click on the Poro to get to the home page.</h3>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "error-page",
  mounted() {},
  computed: {
    ...mapGetters(["getLanguage"]),
  },
  methods: {
    redirectToHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing-lg 0;

  @media (min-width: $md) {
    flex-direction: row;
  }

  img {
    cursor: pointer;
    max-width: 400px;
  }
}

.error-content {
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    padding-left: $spacing-lg;
  }
}
</style>
