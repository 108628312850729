<template>
  <div class="image-wrapper" :id="content.id || ''">
    <div
      v-for="image in content.images"
      :class="[
        'image-item',
        {
          'image-item--multi': content.images.length > 1,
        },
      ]"
      :key="image.sys.id"
    >
      <img :src="image.fields.file.url" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageContainer",
  props: {
    content: Array,
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-md;
  margin-bottom: $spacing-md;
  padding-bottom: $spacing-sm;

  @media (min-width: $md) {
    flex-direction: row;
  }
}

.image-item {
  &--multi {
    max-width: 350px;

    @media (min-width: $md) {
      max-width: 100%;
    }
  }

  img {
    width: 100%;
  }
}
</style>
