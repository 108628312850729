<!-- eslint-disable prettier/prettier -->
<template>
  <div className="teaser-container" :id="content.id || ''">
    <div class="teaser teaser--long">
      <div class="vertical-text">{{ content.sideText }}</div>

      <div v-if="!content.url" class="teaser-card">
        <div class="teaser-card--border"></div>
        <img class="teaser-card__image" :src="content.image.fields?.file?.url" />
        <div class="teaser-card__content">
          <div class="teaser-card__content--category">
            {{ content.subtitle }}
          </div>
          <div class="teaser-card__content--title" v-html="content.headline" />
        </div>
      </div>

      <a v-else class="teaser-card" :href="content.url">
        <div class="teaser-card--border"></div>
        <img class="teaser-card__image" :src="content.image.fields?.file?.url" />
        <div class="teaser-card__content">
          <div class="teaser-card__content--category">
            {{ content.subtitle }}
          </div>
          <div :style="{ fontSize: content.headline?.length > 32 ? '24px' : '' }" class="teaser-card__content--title"
            v-html="content.headline" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'TeaserContainer',
  props: {
    content: [Array, Object],
  },
};
</script>

<style lang="scss">
@import '../assets/scss/_variables.scss';

.vertical-text {
  font-size: 12px;
  transform: rotate(270deg);
  letter-spacing: 6px;
  position: absolute;
  left: -60px;
  top: 60px;
  width: 140px;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1.4em;
    height: 1px;
    margin-left: 0.5em;
    background-color: $sewcase-white;
  }
}

.teaser {
  position: relative;
  width: 100%;
  height: 150px;

  @media (min-width: $md) {
    width: calc(100% - $spacing-xl);
  }

  &-card {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 32px;

    @media (min-width: $md) {
      left: 64px;
    }

    &:after {
      content: '';
      right: -10px;
      top: 0;
      width: 30px;
      height: 30px;
      border-top: 0 solid white;
      border-right: 0 solid white;
      position: absolute;
      transition: all 0.3s ease-in;
      opacity: 0;
      transition-delay: 0.2s;
      z-index: 1;

      @media (min-width: $md) {
        right: 54px;
      }
    }

    @media (min-width: $sm) {
      &:hover {
        &:after {
          border-top: 5px solid $sewcase-black;
          border-right: 5px solid $sewcase-black;
          opacity: 1;
          transition-delay: 0.4s;
        }

        .teaser-card--border {
          clip-path: polygon(0 100%,
              0 0,
              100% 0,
              100% 0,
              100% 100%,
              0 100%,
              0 calc(100% - 2px),
              calc(100% - 2px) calc(100% - 2px),
              calc(100% - 2px) 2px,
              calc(100% - 2px) calc(0% + 2px),
              calc(0% + 2px) calc(0% + 2px),
              calc(0% + 2px) 100%);
          transition-delay: 0.16s;
        }

        .teaser-card__image {
          transform: scale(1.07);
          left: $spacing-md;
        }

        .teaser-card__content {
          margin-left: 7px;
        }

        .teaser-card__content--title {
          letter-spacing: 2px;
        }
      }
    }

    &--border {
      position: relative;
      width: 100%;
      height: 100%;
      left: 10px;
      background: $sewcase-black;
      clip-path: polygon(0 100%,
          0 0,
          calc(100% - 18px) 0,
          100% calc(0% + 18px),
          100% 100%,
          0 100%,
          0 calc(100% - 2px),
          calc(100% - 2px) calc(100% - 2px),
          calc(100% - 2px) calc(0% + 18px),
          calc(100% - 18px) calc(0% + 2px),
          calc(0% + 2px) calc(0% + 2px),
          calc(0% + 2px) 100%);
      transition: all 0.16s ease-in;
      transition-delay: 0.5s;
      z-index: 1;

      @media (min-width: $md) {
        width: calc(100% - $spacing-xl);
      }
    }

    &__image {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 200px;
      height: 150px;
      object-fit: contain;
      transition-delay: 0.5s;
      transition: all 0.7s;

      @media (min-width: $sm) {
        display: block;
      }
    }

    &__content {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-left: $spacing-md;
      left: 10px;
      top: 0;
      width: 255px;
      height: 150px;
      transition-delay: 0.7s;
      transition: all 0.7s;

      @media (min-width: $sm) {
        left: 230px;
        width: 355px;
      }

      &--category {
        font-size: $font-sm;
        color: rgb(11, 198, 227);
        margin-bottom: 10px;
      }

      &--title {
        font-size: $font-xl;
        transition: all 0.16s ease-in;
        word-wrap: break-word;
      }
    }
  }

  &-container {
    display: flex;
    justify-content: center;
    width: calc(100% - 64px);
    height: 150px;
    margin: $spacing-lg 0;
  }
}
</style>
