/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import BasePage from '../views/BasePage.vue';
import ContestPage from '../views/ContestPage.vue';
import SchedulePage from '../views/SchedulePage.vue';
import ImagePage from '../views/ImagePage.vue';
import GalleryPage from '../views/GalleryPage.vue';
import ErrorPage from '../views/ErrorPage.vue';
import LinkTreePage from '../views/LinkTreePage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: BasePage,
  },
  {
    path: '/convention',
    name: 'convention',
    component: BasePage,
  },
  {
    path: '/convention/:name',
    name: 'convention-detail',
    component: BasePage,
  },
  {
    path: '/shootings/:name/:id',
    name: 'convention-images',
    component: ImagePage,
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: BasePage,
  },
  {
    path: '/gallery/:name',
    name: 'gallery-images',
    component: GalleryPage,
  },
  {
    path: '/contest',
    name: 'contest',
    component: ContestPage,
  },
  {
    path: '/tos',
    name: 'tos',
    component: ContestPage,
  },
  {
    path: '/dokomi',
    redirect: '/convention/dokomi',
  },
  {
    path: '/gamescom',
    redirect: '/convention/gamescom',
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: BasePage,
  },
  {
    path: '/faq',
    name: 'faq',
    component: BasePage,
  },
  {
    path: '/test-page',
    name: 'test-page',
    component: BasePage,
  },
  {
    path: '/test-contest',
    name: 'test-contest',
    component: ContestPage,
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: SchedulePage,
  },
  {
    path: '/signme',
    beforeEnter: (to, from, next) => {
      const headers = {
        'Content-Type': 'application/pdf',
        'Content-Disposition': 'attachment; filename="scc-waiver.pdf"',
      };

      const pdfUrl = '/scc-waiver.pdf';
      window.location.href = pdfUrl;

      // Prevent further navigation
      next(false);
    },
  },
  // {
  //   path: '/voting',
  //   beforeEnter() {
  //     location.href = 'https://strawpoll.com/eNg69erEwnA';
  //   },
  // },
  // {
  //   path: '/catwalk',
  //   beforeEnter() {
  //     location.href = 'https://strawpoll.com/eNg69erEwnA';
  //   },
  // },
  {
    path: '/linktree',
    component: LinkTreePage,
  },
  {
    path: '/privacy-policy',
    component: ContestPage,
  },
  {
    path: '/:catchAll(.*)',
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  sensitive: true,
});

export default router;
