<template>
  <!-- <div v-if="!showSchedule" class="sign-in">
    <div class="sign-in-container">
      <div class="sign-in__help-icon" id="help">
        <span>?</span>
        <div class="tooltip">
          <div class="tooltip__container">
            Welcome to the sewcase scheduler. I tried to coply a bit of the
            styles from the League Of Legends Launcher. Just enjoy entering THE
            password to see the scheduler.
            <br />
            <br />
            If you're having trouble logging in, you may need to
            <a href="#" style="color: #cc5050">contact Tana.</a>
          </div>
        </div>
      </div>
      <div class="sign-in__icon"></div>
      <div class="sign-in__headline">Sign in</div>
      <div class="sign-in__login">
        <div class="sign-in__login-input">
          <label for="password">PASSWORD</label>
          <input type="text" v-model="password" id="password" />
        </div>

        <div class="sign-in__remember">
          <input v-model="remember" type="checkbox" />
          <span>Stay signed in</span>
        </div>

        <div
          id="login"
          class="sign-in__login-button"
          @click="loginButtonClick()"
        >
          <svg version="1.1" viewBox="0 0 512 512" xml:space="preserve">
            <polygon
              points="170.667,0 140.48,30.187 259.627,149.333 0,149.333 0,192 259.627,192 140.48,311.147 170.667,341.333 
                             341.333,170.667"
            />
          </svg>
        </div>
      </div>

      <a href="#">Scheduler</a>
      <a href="#">Gamescom 2022</a>
      <div class="version">v1.0.0</div>
    </div>
  </div> -->

  <section
    v-for="(dayList, index) in scheduleList"
    class="component-section component-section--spacing"
    :key="index"
  >
    <div class="day">
      {{ specificDay(index) }}
    </div>
    <g-gantt-chart
      :chart-start="`2022-08-${24 + index} 08:00`"
      :chart-end="`2022-08-${24 + index} 20:30`"
      color-scheme="dark"
      precision="hour"
      bar-start="myBeginDate"
      bar-end="myEndDate"
    >
      <g-gantt-row
        v-for="(rowBar, index) in dayList"
        :immobile="true"
        :label="rowBar[0].rowLabel"
        :bars="rowBar"
        :key="index"
      />
    </g-gantt-chart>
    <!-- <div
      id="login"
      class="sign-in__login-button sign-in__login-button--return"
      @click="backToLogin()"
    >
      Return to login
    </div> -->
  </section>
</template>

<script>
import {
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
} from "../data/schedule/timetable";

export default {
  name: "schedule-page",
  data() {
    return {
      isLoaded: true,
      remember: false,
      showSchedule: false,
      password: "",
      loginBackground: "#e03030",
      loginBorder: "solid 1px #e03030",
      disabledLoginBackground: "transparent",
      disabledLoginBorder: "solid 1px #ccc",
    };
  },
  mounted() {
    // const dateNow = new Date();
    // const dateLastVisited = new Date(this.getCookie("expiry"));
    // const softLoggedIn =
    //   this.getCookie("remember") &&
    //   this.addHoursToDate(dateLastVisited, 12) > dateNow;

    // if (softLoggedIn) {
    //   this.isLoaded = false;
    //   setTimeout(() => {
    //     this.showSchedule = true;
    //     this.isLoaded = true;
    //   }, 15);
    // }

    // const FloatLabel = (() => {
    //   // add active class
    //   const handleFocus = (e) => {
    //     const target = e.target;
    //     target.parentNode.classList.add("active");
    //   };

    //   // remove active class
    //   const handleBlur = (e) => {
    //     const target = e.target;
    //     if (!target.value) {
    //       target.parentNode.classList.remove("active");
    //     }
    //   };

    //   // register events
    //   const bindEvents = (element) => {
    //     const floatField = element.querySelector("input");
    //     floatField.addEventListener("focus", handleFocus);
    //     floatField.addEventListener("blur", handleBlur);
    //   };

    //   // get DOM elements
    //   const init = () => {
    //     const floatContainers = document.querySelectorAll(
    //       ".sign-in__login-input"
    //     );

    //     floatContainers.forEach((element) => {
    //       if (element.querySelector("input").value) {
    //         element.classList.add("active");
    //       }

    //       bindEvents(element);
    //     });
    //   };

    //   return {
    //     init: init,
    //   };
    // })();

    // FloatLabel.init();

    // const passwordInput = document.getElementById("password");
    // const loginComponent = document.getElementById("login");

    // passwordInput.addEventListener("input", () => {
    //   this.loginButtonEvent(passwordInput, loginComponent);
    // });
    setTimeout(() => {
      var barLabels = document.getElementsByClassName("g-gantt-bar-label");
      barLabels = Array.from(barLabels);
      barLabels.forEach((t) => {
        t.addEventListener("click", (event) => {
          var targetElement = event.target || event.srcElement;

          var isAnnoyingElement =
            targetElement.classList.contains("g-gantt-item-label");

          if (isAnnoyingElement) {
            targetElement.remove();
            return;
          }

          var existingItem = targetElement.querySelector(".g-gantt-item-label");
          if (existingItem) {
            existingItem.remove();
            return;
          }

          var descElement = document.createElement("div");
          descElement.classList.add("g-gantt-item-label");
          descElement.textContent = targetElement.innerText;

          if (targetElement.classList.contains("g-gantt-bar-label"))
            targetElement.appendChild(descElement);
        });
      });
    }, 1000);
  },
  computed: {
    scheduleList() {
      return [
        ...[wednesday],
        ...[thursday],
        ...[friday],
        ...[saturday],
        ...[sunday],
      ];
    },
  },
  methods: {
    addHoursToDate(date, hours) {
      return new Date(new Date(date).setHours(date.getHours() + hours));
    },
    backToLogin() {
      document.cookie = "remember=";
      document.cookie = `expiry=Thu, 01 Jan 1970 00:00:00 GMT`;
      this.$router.go();
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
    loginButtonEvent(passwordInput, loginComponent) {
      if (passwordInput.value != "") {
        loginComponent.style.background = this.loginBackground;
        loginComponent.style.border = this.loginBorder;
      } else {
        loginComponent.style.background = this.disabledLoginBackground;
        loginComponent.style.border = this.disabledLoginBorder;
      }
    },
    loginButtonClick() {
      const passwordInput = document.getElementById("password");
      const loginComponent = document.getElementById("login");

      if (passwordInput.value == "piltover") {
        this.isLoaded = false;
        this.setSoftLoginCookies();

        setTimeout(() => {
          this.showSchedule = true;
          this.isLoaded = true;
        }, 2000);
      } else if (passwordInput.value.length) {
        this.isLoaded = false;

        setTimeout(() => {
          loginComponent.style.background = this.disabledLoginBackground;
          loginComponent.style.border = this.disabledLoginBorder;
          this.isLoaded = true;
        }, 1000);
      }
    },
    setSoftLoginCookies() {
      document.cookie = "remember=true";
      document.cookie = `expiry=${new Date()}`;
    },
    specificDay(i) {
      return i == 0
        ? "MITTWOCH"
        : i == 1
        ? "DONNERSTAG"
        : i == 2
        ? "FREITAG"
        : i == 3
        ? "SAMSTAG"
        : "SONNTAG";
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_transitions.scss";

.day {
  font-size: 24px;
  margin: $spacing-lg auto $spacing-sm;
  text-align: center;
}

.component-section--spacing {
  margin: $spacing-xl auto;
  max-width: $lg;
}

.g-gantt {
  &-tooltip {
    display: none;
  }
  &-bar {
    background-color: $sewcase-black !important;
    border: 1px solid $sewcase-white;
    border-bottom: none;
    border-top: none;
    font-weight: 600;

    &-label > div {
      color: $sewcase-white !important;
    }
  }
  &-row {
    background: $sewcase-white !important;
    &-label {
      &:empty {
        display: none !important;
      }
    }
  }
}

.g-gantt-bar {
  overflow: unset !important;
}

.g-gantt-bar-label {
  font-size: 12px !important;
  padding: 0 4px !important;
  position: relative;

  > div {
    z-index: -1 !important;
  }
  > div.g-gantt-item-label {
    color: $sewcase-secondary-dark !important;
    z-index: 1 !important;
  }
}

.g-gantt-item-label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  left: 0;
  top: 0;
  padding: 0 8px;
  white-space: nowrap;
  background: $sewcase-grey;
  min-width: 100%;
  height: 100%;
  border: 2px solid $sewcase-secondary;
}

.g-gantt-tooltip {
  display: none !important;
}

.g-gantt-row {
  padding: 8px 0 !important;
  &:last-child {
    margin-bottom: 8px;
  }
}

.g-gantt-row-label {
  background: rgba($sewcase-black, 0.4) !important;
}

.g-timeaxis-hour-pin {
  background-color: $sewcase-black !important;
}

.g-timeunit {
  background-color: $sewcase-white !important;
  color: $sewcase-black !important;
}

.g-timeunits-container {
  margin-bottom: 2px;
}
.g-upper-timeunit {
  background-color: $sewcase-black !important;
}

#g-gantt-chart {
  background: white !important;
  border: 2px solid $sewcase-black;
  border-radius: 0 !important;
  margin-bottom: 16px;
  overflow: hidden !important;
}

.sign-in {
  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 768px;
    padding: 12px;
    position: relative;
    z-index: 1;

    @media (min-width: $md) {
      margin: 24px;
      padding: 48px;
    }

    > div,
    > a {
      user-select: none;
    }
  }

  &__help-icon {
    background: #aaa;
    position: absolute;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    right: 45px;

    > span {
      color: white;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  &__icon {
    background: url("../assets/images/poro_sewcase.png");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  &__headline {
    font-size: 24px;
    margin: 0 auto;
    margin-bottom: 20px;
    color: #333;
  }

  &__login {
    margin-bottom: 60px;

    &-input {
      background: #eee;
      border-radius: 3px;
      box-sizing: border-box;
      margin-bottom: 8px;
      padding: 0 8px;
      position: relative;
      width: 100%;

      input {
        background: transparent;
        border: none;
        font-size: 16px;
        color: #333;
        outline: 0;
        padding: 16px 0 10px;
        width: 100%;
      }

      label {
        cursor: text;
        font-size: 12px;
        position: absolute;
        transform-origin: top left;
        transform: translate(0, 16px) scale(1);
        transition: all 0.1s ease-in-out; //speed
      }

      /** active label */
      &.active {
        label {
          //move the x coordinate and reduce size
          transform: translate(0, 4px) scale(0.8);
        }
      }
    }
  }

  &__remember {
    font-size: 14px;
    margin-bottom: 50px;
  }

  &__quick-login {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin: 15px 0;

    .social-media {
      width: 30%;
      border-radius: 5px;
      padding: 5px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      > svg {
        margin: 0 auto;
        display: block;
      }
    }

    .facebook {
      background: #3b5998;
      transition: all 0.1s ease-in;

      &:hover {
        background: #2b3998;
      }
      > svg {
        height: 100%;
      }
    }

    .apple {
      background: #000;
      > svg {
        height: 100%;
      }
    }

    .google {
      border: solid 1px #ccc;
      transition: all 0.1s ease-in;

      &:hover {
        background: #eee;
      }
      > svg {
        height: 100%;
      }
    }
  }

  &__login-button {
    background: transparent;
    border: solid 1px #ccc;
    border-radius: 20px;
    padding: 10px;
    width: 64px;
    height: 64px;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.1s ease-in;

    &--return {
      background: linear-gradient(to right, #b03030 50%, transparent 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      border: 2px solid $sewcase-black;
      border-radius: 0;
      color: $sewcase-black;
      margin: $spacing-md 0;
      height: auto;
      width: 160px;
      text-align: center;
      transition: 0.3s all ease;

      &:hover {
        background-position: left bottom;
        border: 2px solid #b03030;
        color: $sewcase-white;
      }
    }

    > svg {
      padding-left: 10px;
      padding-top: 10px;

      > polygon {
        fill: #eee;
      }
    }
  }
}

.tooltip {
  display: none;
  position: absolute;
  width: 200px;
  padding: 15px;
  top: -33px;
  left: -200px;
  z-index: 2;

  &:hover {
    display: block;
  }

  &__container {
    background: white;
    padding: 15px;
    box-shadow: 0px 0px 5px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    text-align: left;

    &:before {
      content: "";
      width: 0px;
      height: 0px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
      position: absolute;
      right: 5px;
    }
  }
}
</style>
