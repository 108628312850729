<template>
  <div
    :class="[
      'text-image-container',
      {
        'text-image-container--right':
          content.style == 'Image Right' ||
          content.style == 'Image Right Special Border',
        'text-image-container-url': content.imageUrl,
        'text-image-container-nb': content.style == 'Neu Brutalism',
        'text-image-container-nb text-image-container-nbr':
          content.style == 'Neu Brutalism Right',
      },
    ]"
    :id="content.id || ''"
  >
    <div
      :class="[
        'text-image-special-border',
        {
          'text-image-special-border--right':
            content.style == 'Image Right' ||
            content.style == 'Image Right Special Border',
          'text-image-shadow-hover': shadowHoverComputed,
        },
      ]"
    ></div>
    <img
      :src="content.image.fields?.file?.url"
      @click="clickHandler(content.imageUrl)"
      @mouseover="
        () =>
          (shadowHover =
            content.style == 'Neu Brutalism' ||
            content.style == 'Neu Brutalism Right')
      "
      @mouseleave="() => (shadowHover = false)"
    />
    <div
      :class="[
        'text-image-content',
        {
          'text-image-content--right':
            content.style == 'Image Right' ||
            content.style == 'Image Right Special Border',
          'text-image-shadow-hover': shadowHoverComputed,
        },
      ]"
    >
      <RichTextRenderer :document="content.content" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import RichTextRenderer from "contentful-rich-text-vue-renderer";

export default {
  name: "TextImage",
  props: {
    content: [Array, Object],
  },
  data() {
    return {
      shadowHover: null,
    };
  },
  components: {
    RichTextRenderer,
  },
  computed: {
    shadowHoverComputed() {
      return this.shadowHover;
    },
  },
  methods: {
    clickHandler(url) {
      if (url?.length) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
.text-image {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacing-xl;
    position: relative;

    @media (min-width: $sm) {
      flex-direction: row;
    }

    &.text-image-container-url {
      img {
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        transition: all 0.2s ease;

        &:hover {
          top: 12px;
          left: -12px;
        }
      }

      &.text-image-container--right {
        img {
          cursor: pointer;
          position: relative;
          top: 0;
          right: 0;
          left: auto;
          transition: all 0.2s ease;

          &:hover {
            top: 12px;
            right: -12px;
            left: auto;
          }
        }
      }
    }

    img {
      background: black;
      width: 250px;
      height: 250px;
      z-index: 2;

      @media (min-width: $lg) {
        width: 300px;
        height: 300px;
      }
    }

    &--right {
      flex-direction: column;

      @media (min-width: $sm) {
        flex-direction: row-reverse;
      }
    }
  }

  &-content {
    @media (min-width: $sm) {
      padding-left: $spacing-md;
    }

    &--right {
      @media (min-width: $sm) {
        padding-left: 0;
        padding-right: $spacing-lg;
      }
    }
  }

  &-special-border {
    width: 250px;
    height: 250px;
    background-color: $sewcase-black;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 8px;
    transform: translateX(calc(-50% - 8px));

    @media (min-width: $sm) {
      left: -12px;
      top: 12px;
      transform: none;
    }

    @media (min-width: $lg) {
      height: 300px;
      width: 300px;
    }

    &--right {
      @media (min-width: $sm) {
        left: auto;
        right: -12px;
        top: 12px;
      }
    }
  }
}

.text-image-container {
  &.text-image-container-nb.text-image-container-nbr {
    flex-direction: column;

    @media (min-width: $sm) {
      flex-direction: row-reverse;
    }

    .text-image-special-border {
      left: 50%;
      right: unset;
      transform: translateX(calc(-50% + 12px));
      
      @media (min-width: $sm) {
        left: 50%;
        right: auto;
        transform: translateX(calc(50% - 40px));
      }

      @media (min-width: $md) {
        left: auto;
        right: -12px;
        transform: unset;
      }
      
      &.text-image-shadow-hover {
        right: 0;
      }
    }
  }
  &.text-image-container-nb {
    gap: 24px;

    @media (min-width: $sm) {
      justify-content: unset;
      align-items: unset;
    }

    img {
      border-radius: 8px;
      border: 3px solid $sewcase-black;

      &:hover {
        top: 0;
        left: 0;
      }
    }

    .text-image-special-border {
      border-radius: 8px;
      left: 50%;
      right: unset;
      transform: translateX(calc(-50% + 12px));
      
      @media (min-width: $sm) {
        left: 12px;
        transform: unset;
      }
    }

    .text-image-content {
      background-color: $sewcase-nb-rose;
      border: 3px solid $sewcase-black;
      border-radius: 8px;
      padding: 0 $spacing-md;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        background: #111;
        border-radius: 8px;
        right: -16px;
        bottom: -16px;
        width: 100%;
        height: 100%;
        transition: all 0.2s ease;
        z-index: -1;
      }

      &.text-image-shadow-hover {
        &:before {
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .text-image-special-border {
    transition: all 0.3s ease;

    &.text-image-shadow-hover {
      left: 0;
      top: 0;
    }
  }
}
</style>
