<template>
  <div class="social-media-wrapper" :id="content.id || ''">
    <a
      v-for="item in content"
      class="social-media-item"
      rel="noopener noreferrer"
      target="_blank"
      :href="item.fields.url"
      :key="item.sys.id"
    >
      <img :src="item.fields.image.fields.file.url" :alt="item.title" />
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
  props: {
    content: Array,
  },
  computed: {},
};
</script>

<style lang="scss">
.social-media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  .social-media-item {
    margin: 0 16px;

    > img {
      width: 54px;
    }
  }
}
</style>
